import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const Footer = () => (

  <StaticQuery
    query={graphql`
      query {
        posts: allMdx(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {fileAbsolutePath: {regex: "/(posts)/"  }}
          limit: 7
        )  {
          nodes {
            frontmatter {
              title
            }
            slug
          }
        }

        projects: allMdx(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {fileAbsolutePath: {regex: "/(projects)/"  }}
          limit: 7
        )  {
          nodes {
            frontmatter {
              title
            }
            slug
          }
        }

        interests: allMdx(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {fileAbsolutePath: {regex: "/(interests)/"  }}
          limit: 7
        )  {
          nodes {
            frontmatter {
              title
            }
            slug
          }
        }
      }
    `}
    render={( data ) => (

      <footer className="container pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-6 col-md">
            
          </div>

          <div className="col-6 col-md">
            <h5>Posts</h5>
            <ul className="list-unstyled text-small link-anim">
              {data.posts.nodes.map(({ frontmatter, slug }) => (
                <li>
                  <Link className="text-muted" to={`/${slug}`}>{frontmatter.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          
          <div className="col-6 col-md">
            <h5>Projects</h5>
            <ul className="list-unstyled text-small link-anim">
              {data.projects.nodes.map(({ frontmatter, slug }) => (
                <li>
                  <Link className="text-muted" to={`/${slug}`}>{frontmatter.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          
          <div className="col-6 col-md">
            <h5>Interests</h5>
            <ul className="list-unstyled text-small link-anim">
              {data.interests.nodes.map(({ frontmatter, slug }) => (
                <li>
                  <Link className="text-muted" to={`/${slug}`}>{frontmatter.title}</Link>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </footer>
    )}
  />
)

export default Footer


    







