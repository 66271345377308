/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

//import "@popperjs/core/dist/umd/popper.min.js";
//import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.js"
import 'bootstrap-icons/font/bootstrap-icons.css';

import "./src/components/styles.scss";

import { rootWrapper } from "./root-wrapper";
export const wrapPageElement = rootWrapper;

require("prismjs/themes/prism-tomorrow.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css")