import * as React from "react"
import { Link } from "gatsby"

const Navbar = () => {
  return (
    <nav className="navbar navbar-light bg-white navbar-expand-lg">
      <div className="container"> 
        


        <button className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbar" aria-controls="navbar"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav me-auto">
          </ul>
          <ul className="navbar-nav justify-content-end ms-auto">
            <li className="nav-item">
              <Link to="/#posts" className="nav-link">Posts</Link>
            </li>
            <li className="nav-item">
              <Link to="/#projects" className="nav-link">Projects</Link>
            </li>
            <li className="nav-item">
              <Link to="/#about-me" className="nav-link">About Me</Link>
            </li>
          </ul>
        </div>

      </div>
    </nav>
  )
}

export default Navbar
