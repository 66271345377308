import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Link } from "gatsby";
import SrcLeft from "../../../../src/components/posts/srcleft.mdx";
import SrcRight from "../../../../src/components/posts/srcright.mdx";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  SrcLeft,
  SrcRight,
  React
};