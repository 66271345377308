import React from "react"

const SubFooter = () => {
    return (
      <section className="pt-5 pb-5 bg-dark text-white">
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start">
                <p>Copyright Chad Tomlinson. All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </section>
    )
  }
  
  export default SubFooter
  