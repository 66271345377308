import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    {children}
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <figure className="text-start">
      <figcaption className="blockquote-footer">
        <a href={props.src}>{props.text}</a>
      </figcaption>
    </figure>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      