import React from "react";

import Navbar from "./navbar";
import SubFooter from "./sub.footer";
import Footer from "./footer";
import ScrollToTop from "./scrolltotop";

export const Layout = ({ children }) => {
  return (
    <div className="d-flex flex-column h-100">
      <Navbar />
      
      <main>{children}</main>

      <Footer />
      <SubFooter />
      <ScrollToTop />
    </div>
  );
};